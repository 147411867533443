import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [files, setFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState('');  // Track the current directory path
  const [detection, setDetection] = useState({});

  useEffect(() => {
    fetchFiles(currentPath);  // Fetch files on component mount
  }, [currentPath]);

  const fetchFiles = async (path) => {
    try {
      const response = await axios.get(`http://142.44.249.96:5000/api/files`, {
        params: {
          path: path || '',  // Pass the current path to backend
        },
      });
      setFiles(response.data.files);  // Update the state with the fetched files
      setDetection(response.data.detection);  // Set the detection results
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileClick = (file) => {
    if (file.type === 'dir') {
      // If it's a directory, change the current path
      setCurrentPath(file.path);
    } else {
      // If it's a file, you could handle the file display here (e.g., open in a new tab)
      window.open(file.download_url, '_blank');
    }
  };

  const handleBackClick = () => {
    // Navigate back one directory
    const parentPath = currentPath.split('/').slice(0, -1).join('/');
    setCurrentPath(parentPath);
  };

  return (
    <div className="App">
      <h1>Files in groovio-mobile ({currentPath || 'root'})</h1>
      {currentPath && (
        <button onClick={handleBackClick}>Go Back</button>
      )}

      {/* Display framework detection results */}
      <div>
        <h3>Framework/Language Detection:</h3>
        <ul>
          <li>React.js: {detection.isReactJS ? 'Yes' : 'No'}</li>
          <li>React Native: {detection.isReactNative ? 'Yes' : 'No'}</li>
          <li>Python: {detection.isPython ? 'Yes' : 'No'}</li>
          <li>Django: {detection.isDjango ? 'Yes' : 'No'}</li>
        </ul>
      </div>

      <ul>
        {files.map((file) => (
          <li key={file.sha} onClick={() => handleFileClick(file)} style={{ cursor: 'pointer' }}>
            {file.type === 'dir' ? '📁' : '📄'} {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;

